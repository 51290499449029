import { graphql } from 'gatsby'
import { map } from 'rambdax'
import PageLayout from '../components/PageLayout/PageLayout'
import styles from './corporateFinancialTechnology.module.scss'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import ZoneIconBox, {
  ZoneIconBoxListProps,
} from '@algbra/ui/zones/IconBoxList/IconBoxList'
import ZoneInlineWaitlist, {
  ZoneInlineWaitlistProps,
} from '../zones/InlineWaitlist/InlineWaitlist'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags } from '@algbra/utils/metaTags'

type ZoneItemType = ZoneIconBoxListProps | ZoneInlineWaitlistProps

export default function CorporateFinancialTechnology({
  pageContext,
  data,
  location,
}) {
  const zones: ZoneItemType[] = data.strapiGQL.corporatePage.contentZones

  return (
    <PageLayout>
      <SubpageHeader
        title={pageContext.title}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
      />

      <Whitespace type="md" style={{ paddingBottom: 0 }}>
        <div className={styles.imageContainer}>
          <div className={styles.image}></div>
        </div>
      </Whitespace>

      {map(zone => {
        switch (zone.__typename) {
          case 'StrapiGQL_ComponentZonesInlineWaitlist':
            return <ZoneInlineWaitlist key={zone.id} {...zone} />
          case 'StrapiGQL_ComponentZonesIconBoxList':
            return <ZoneIconBox key={zone.id} {...zone} />
          default:
            return null
        }
      }, zones)}
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      corporatePage(id: $id) {
        ...StrapiGQL_CorporatePagesWithContentZones
      }
    }
  }
`
